<template>
	<BootstrapModal :isActive="showVerifyUser" @submit="checkCode" :submitDisabled="!code"
		@cancel="setShowVerifyUser(false); resetForm()" submitText="Verify code" heading="Please confirm that it is you"
		class="phone-number-confirmation-popup" :showHeader="false" :showCancel="false" :showOkay="codeWasSent">
		<div v-if=" savedAdvisor ">
			<div class="row">
				<div class="col-3">
					<avatar :username=" savedAdvisor.name " :src=" savedAdvisor.avatar " :size=" 80 " />
				</div>
				<div class="col">
					<div><strong>Connecting with</strong></div>
					<h4 class="mt-1 mb-0">{{ savedAdvisor.name }}</h4>
					<div class="mt-1" v-if=" getFreeMinutes(savedAdvisor) "><strong>Free minutes: </strong>{{
						getFreeMinutes(savedAdvisor) }}</div>
				</div>
			</div>
		</div>

		<p class="text-center mt-2">
			Verify your phone number to prevent misuse of our service.<br />
			Help us confirm you're a human.
		</p>
		<p class="mt-3">Add a phone number</p>

		<vue-tel-input v-model=" phone " name="phone" class="form-control" :disabled=" !shouldAddPhone "
			ref="verification_phone" v-on:keyup.enter=" sendCode " :defaultCountry=" countryCode " />
		<div class="invalid-feedback display-block" v-if=" phoneError " v-html=" phoneError "></div>
		<button class="btn btn-success mt-2 mb-4" v-if=" !codeWasSent " @click=" sendCode " :disabled=" !phone ">Send verification
			code</button>

		<div v-if=" codeWasSent ">
			<button class="btn btn-link mt-2 mb-4" @click=" sendCode " :disabled=" !phone ">Re-send verification code</button>

			<p>A verification code has been sent to your phone. Please enter it in the field below.</p>
			<input v-model=" code " name="verification_code" class="form-control font-medium-3"
				placeholder="Verification code" ref="verification_code" v-on:keyup.enter=" checkCode " />
		</div>
		<div class="invalid-feedback display-block" v-if=" codeError " v-html=" codeError "></div>
		<div class="alert alert-danger mt-3" v-if=" errorMessage ">
			{{ errorMessage }}
		</div>
		<div class="alert alert-success mt-3" v-if=" success ">
			Your phone number has been successfully verified.
		</div>
	</BootstrapModal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { chatStore } from '/src/store/chat'

import BootstrapModal from '/src/components/BootstrapModal.vue'
import Avatar from '/src/components/Avatar.vue'

import { phoneForDb } from '/src/helpers/phoneForDb'

export default {
	name: "PhoneNumberConfirmation",
	components: {
		BootstrapModal,
		Avatar,
	},
	data() {
		return {
			phone: '',
			code: '',
			errorMessage: '',
			phoneError: '',
			codeError: '',
			codeWasSent: false,
			// advisor: null,
			// advisorLoading: false,
			countryCode: '',
			success: false,
		}
	},
	computed: {
		...mapState(usersStore, [
			'showVerifyUser',
			'currentUser',
		]),
		...mapState(chatStore, [
			'savedAdvisor',
		]),
		shouldAddPhone() {
			return !this.currentUser?.phone
		},
	},
	watch: {
		showVerifyUser(newValue) {
			if (newValue && this.phone) {
				this.sendCode()
			}
		},
		currentUser() {
			if (this.currentUser?.phone) {
				this.phone = this.currentUser.phone
			}

			if (this.currentUser?.country_code) {
				this.countryCode = this.currentUser.country_code
			} else if (this.currentUser?.id) {
				// If no country code is specified for the user - using IP for this.
				this.$api.post('/api/user/ip-country-code', {})
					.then(res => {
						this.countryCode = res.data.country_code
					})
			}
		},
	},
	mounted() {
		// if (this.$route.query.action && this.$route.query.param) {
		// 	this.getAdvisor();
		// }
	},
	methods: {
		...mapActions(usersStore, [
			'setShowVerifyUser',
			'setLastVerifyUserResult',
			'getFreeMinutes',
		]),
		sendCode() {
			this.errorMessage = ''
			this.success = false

			let params = {}
			if (this.shouldAddPhone) {
				params.phone = phoneForDb(this.phone)
			}
			this.codeWasSent = true
			console.log('params', params)
			this.$api.post('/api/user/send-sms-code', params)
				.then(res => {
					console.log(res)
					this.phoneError = ''
					this.$refs.verification_code.focus()
				})
				.catch(err => {
					// console.info(err)
					if (err.response) {
						if (err.response.error) {
							this.errorMessage = err.response.error
						}
						if (err.response.phone) {
							this.$refs.verification_phone.focus()
							this.phoneError = err.response.phone.join("<br>")
						}
					}
				})
		},
		resetForm() {
			this.phone = this.currentUser.phone
			this.code = ''
		},
		checkCode() {
			this.errorMessage = ''
			this.phoneError = ''
			this.codeError = ''
			this.success = false

			let params = {
				code: this.code,
			}
			if (this.shouldAddPhone) {
				params.phone = phoneForDb(this.phone)
			}
			this.$api.post('/api/user/check-sms-code', params)
				.then(res => {
					this.success = true
					setTimeout(function () {
						this.setLastVerifyUserResult(true)
						this.setShowVerifyUser(false)
						this.resetForm()
					}.bind(this), 2000)
					// console.log(res)
				})
				.catch(err => {
					// this.setLastVerifyUserResult(false)
					// this.setShowVerifyUser(false)
					// console.info(err)
					if (err.response) {
						if (err.response.error) {
							this.errorMessage = err.response.error
						}
						if (err.response.phone) {
							this.phoneError = err.response.phone.join("<br>")
						}
						if (err.response.code) {
							this.codeError = err.response.code.join("<br>")
						}
					}
				})
		},
	},
}
</script>

<style lang="scss">
@import "/src/styles/variables";

.phone-number-confirmation-popup .modal {
	z-index: 1002001 !important;
}

.phone-number-confirmation-popup .modal-backdrop {
	z-index: 1002000 !important;
}

.phone-number-confirmation-popup .row h4 {
	text-transform: uppercase;
	font-weight: bold;
	color: var(--primary);
	letter-spacing: 0.05rem;
	font-size: 1.12rem;
}
</style>
