<template>
  <BootstrapModal v-if="isUser || isAdvisor" :isActive="isActiveMissedChatModal" heading="" :showClose="isUser" :is-error="true">
    <template #header>
      <div class="d-flex justify-content-center align-items-center full-width">
        <h6 v-if="isUser" class="text-xs text-center mb-0 text-center">The Advisor didn’t answer.</h6>
        <h6 v-else class="text-xs text-center mb-0 text-center">You have missed a chat request.</h6>
      </div>
    </template>

    <div v-if="isUser">
      <p @click="seeOtherAdvisors" class="text-center text-primary text-decoration-underline">You can choose another advisor with the same per minute fee.</p>
      <p class="text-center">We will also notify you when the initial advisor gets online.</p>
      <h3 class="text-center" style="margin-bottom: -26px; ">
        <span style="background: white"> {{ counter }} </span>
      </h3>
    </div>
    <div v-else>
      <p class="text-center mb-0 pt-3 pb-3">You have missed a chat request from this client. We have made your status <b>OFFLINE</b>.</p>
    </div>
    <template #buttons>
      <div class="d-flex justify-content-center full-width">
        <div class="d-flex full-width gap-3" v-if="isUser">
          <div class="width-50-per">
            <button class="btn btn-primary full-width" @click="seeOtherAdvisors">See other advisors now</button>
          </div>
          <div class="width-50-per">
            <button class="btn btn-success full-width" @click="activateSendMessageModal">Send free message to Advisor</button>
          </div>
        </div>
        <div class="d-flex full-width gap-3" v-else-if="isAdvisor">
          <div class="width-50-per">
            <button class="btn btn-success full-width" @click="activateSendMessageModal">Send message to
              client</button>
          </div>
          <div class="width-50-per">
            <button type="button" class="btn btn-danger full-width" @click="setMissedChatModalState(false)">Close</button>
          </div>
        </div>
      </div>


    </template>
  </BootstrapModal>
</template>

<script>
import Avatar from '/src/components/Avatar.vue'

import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { inboxStore } from '/src/store/inbox'
import { chatStore } from '/src/store/chat'
import BootstrapModal from "./BootstrapModal.vue";

export default {
    components: {
      BootstrapModal,
        Avatar,
    },
    props:["acceptSeconds"],
    data() {
        return {
          counter: 15,
        }
    },
    watch: {
        async isActiveMissedChatModal(value) {
            if (value) {
              document.body.classList.add('with-modal');
              if(this.isUser){
                const counterInterval = setInterval(() => {
                  this.counter--;
                  if(this.counter <= 0){
                    clearInterval(counterInterval)
                    this.setMissedChatModalState(false)
                  }
                },  1000)
              }

            } else {
                document.body.classList.remove('with-modal');
            }
        },
    },
    methods: {
        ...mapActions(usersStore, [
            'setMissedChatModalState',
        ]),
        ...mapActions(inboxStore, [
            'openMessageModal',
        ]),
        seeOtherAdvisors() {
            this.setMissedChatModalState(false);
            this.$router.push('/advisors/online');
        },
        activateSendMessageModal() {
            this.setMissedChatModalState(false);
            this.$api.get(`/api/user/${this.lastChatRequestUser.id}`)
                .then(res => this.openMessageModal(res.data))
            //                this.openMessageModal(this.lastChatRequestUser);
        }
    },
    computed: {
        ...mapState(usersStore, [
            'currentUser',
            'isActiveMissedChatModal',
            'isUser',
            'isAdvisor',
            'getUserById',
        ]),
        ...mapState(chatStore, [
            'lastChatRequestUser',
        ]),
    },
}
</script>

<style scoped>
p{
  font-size: 14px;
}
</style>
