<template>
    <div class="popup" :class="{ 'is-active': stayOnlineCountdownTimer }" v-if="isAdvisor">
        <div class="popup-container">
            <h3 class="popup-heading is-timer" :class="{ 'is-warning': stayOnlineCountdownTimerSeconds < 30 }">
                {{ stayOnlineCountdownTimerSeconds.toString().toHHMMSS() }}
            </h3>
            <p>You didn't make any actions for a long time. Are you still here?</p>
            <section class="buttons-wrapper">
                <button class="btn btn-danger" :class="{ 'is-loading': changeStatusLoading }" @click="goOfflineAction()">
                    Go offline
                </button>
                <button class="btn btn-success" @click="imHereAction">
                    Stay online
                </button>
            </section>
        </div> <!-- popup-container -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { presenceStore } from '/src/store/presence'

export default {
    data() {
        return {}
    },
    methods: {
        ...mapActions(presenceStore, [
            'imHereAction',
            'goOfflineAction',
        ]),
    },
    computed: {
        ...mapState(usersStore, [
            'currentUser',
            'changeStatusLoading',
            'isAdvisor',
        ]),
        ...mapState(presenceStore, [
            'stayOnlineTimer',
            'stayOnlineCountdownTimer',
            'stayOnlineCountdownTimerSeconds',
        ]),
        isVisible() {
            return this.stayOnlineCountdownTimer !== null;
        }
    }
}
</script>
