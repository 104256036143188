<template>
  <div class="w-full">
    <Link href="/dashboard/payments" class="flex items-center" data-cy="payments">
      <font-awesome-icon icon="money-bill-alt" />
      <span>Payments <span v-show="currentUser && (currentUser.amount || Number(currentUser.amount) === 0)">(${{
          Number(currentUser.amount).toFixed(2) }})</span></span>
    </Link>
  </div>
  <div class="w-full">
    <Link href="/dashboard/inbox" class="nav-link" data-cy="inbox">
      <font-awesome-icon icon="comments" />
      <span>Inbox <span v-show="unreadInbox">({{ unreadInbox }})</span></span>
    </Link>
  </div>
  <div class="w-full">
    <Link href="/dashboard/history" class="nav-link" data-cy="chats">
      <font-awesome-icon icon="comment" />
      <span>Chats</span>
    </Link>
  </div>
  <div class="w-full">
			<Link href="/dashboard/my-advisors" class="nav-link" data-cy="advisors">
			<font-awesome-icon icon="users" />
			<span>My advisors</span>
			</Link>
  </div>
  <div class="w-full">
    <Link href="/dashboard/subscriptions" class="nav-link" data-cy="subscriptions">
      <font-awesome-icon icon="user-check" />
      <span>My Subscriptions</span>
    </Link>
  </div>
			<div class="w-full">
        <Link href="/dashboard/profile/edit" class="nav-link" data-cy="edit-profile">
          <font-awesome-icon icon="user" />
          <span>Edit Profile</span>
        </Link>
      </div>
  <div class="w-full">
    <Link href="/dashboard/profile/settings" class="nav-link" data-cy="account-settings">
      <font-awesome-icon icon="user-shield" />
      <span>Account Settings</span>
    </Link>
  </div>
  <div class="w-full">
    <Link href="javascript:;" class="nav-link" data-cy="logout" @click="$auth.logout()">
      <font-awesome-icon icon="power-off" />
      <span>Logout</span>
    </Link>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { rootStore } from '/src/store/root'
import vClickOutside from 'click-outside-vue3'

import Link from '/src/components/Link.vue'
import CategoriesMenu from '/src/layouts/CategoriesMenu.vue'
import SearchMenu from '/src/layouts/SearchMenu.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faMoneyBillAlt, faComments, faComment, faUsers, faTimes, faUser, faUserCheck, faUserShield, faPowerOff } from '@fortawesome/free-solid-svg-icons'

export default {
	name: "UserMenu",
	props: {
		notPromoPage: false,
	},
	data() {
		return {
		}
	},
	components: {
		Link,
		CategoriesMenu,
		SearchMenu,
		FontAwesomeIcon,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	methods: {
		...mapActions(usersStore, [
			'getUnreadInbox',
		]),
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
		]),
		...mapState(rootStore, [
			'smallScreenMode',
		]),
		unreadInbox() {
			return this.getUnreadInbox()
		},
	}
}
</script>

<style lang="scss" scoped>
.main-menu-navigation li {
	margin-right: 0.5rem;
}
</style>
