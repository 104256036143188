<template>
  <div class="relative dropdown-nav-item" @click="isOpen = !isOpen">
    <a class="flex items-center justify-between w-full lg:w-auto" :class="[(isActiveUrl ? $router.linkActiveClass : '')]">
      <slot />
      <se-icon name="chevron-down" :size="22" class="ml-4 hidden lg:!block lg:visible" />
      <se-icon name="chevron-right" :size="20" class="ml-4 lg:hidden self-end !mb-2" />
    </a>
    <nav :class="{ 'is-open': isOpen }">
      <div
        class="p-16 border-b border-neutral-20 w-full mb-8 lg:hidden"
        :class="props.returnClasses"
        @click.stop="isOpen = false"
      >
        <div class="cursor-pointer">
          <se-icon name="arrow-left" :size="24" />
        </div>
      </div>
      <Link :href="`/advisors`">
        All <span class="capitalize">{{ theme.general.advisors }}</span>
      </Link>
      <Link v-for="category of categories" :key="category.id" :href="`/advisors/${category.url}`">
        {{ category.name }}
      </Link>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import Link from '../../../components/Link.vue';
import { usersStore } from '../../../store/users';
import SeIcon from '../../ui-kit/icon/Icon.vue';
import { getGlobalProperties } from '../../helpers/getGlobalProperties';
import { theme } from '../../../../theme';

const props = defineProps<{
  returnClasses?: string;
}>();

const { $route, $router } = getGlobalProperties();
const { categories } = storeToRefs(usersStore());
const isActiveUrl = computed(() => categories.value?.filter((c: any) => `/advisors/${c.name}` === $route.url).length);

const isOpen = ref(false);
</script>

<script lang="ts">
export default {
  name: 'SeHeaderCategories',
};
</script>
