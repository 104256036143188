<template>
	<ul data-cy="nav" class="main-menu-navigation nav navbar-nav"
		:class="{ navigation: smallScreenMode }">
    <SearchMenu v-if="notPromoPage && smallScreenMode" />
    <div class="divider" />
    <li class="nav-item" v-show="smallScreenMode">
      <Link class="nav-link font-weight-bold" href="/advisors/online"><span>Online {{ $theme.captions.Advisors
			}}</span></Link>
    </li>
    <CategoriesMenu v-if="notPromoPage && smallScreenMode" />

    <div class="divider" />
		<li class="nav-item" @click="hideSideBarMenu">
			<Link href="/" class="nav-link" data-cy="dashboard">
			<font-awesome-icon icon="home" />
			<span>Home</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu">
			<Link href="/admin-dashboard/sales" class="nav-link" data-cy="sales">
			<font-awesome-icon icon="money-check" />
			<span>Sales</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="/dashboard/profile/edit" class="nav-link" data-cy="edit-profile">
			<font-awesome-icon icon="user" />
			<span>Edit Profile</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="/dashboard/profile/settings" class="nav-link" data-cy="account-settings">
			<font-awesome-icon icon="user-shield" />
			<span>Account Settings</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="javascript:;" class="nav-link" data-cy="logout" @click="$auth.logout()">
			<font-awesome-icon icon="power-off" />
			<span>Logout</span>
			</Link>
		</li>
	</ul>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { rootStore } from '/src/store/root'

import Link from '/src/components/Link.vue'
import CategoriesMenu from '/src/layouts/CategoriesMenu.vue'
import SearchMenu from '/src/layouts/SearchMenu.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faComments, faCog, faCheckCircle, faChartArea, faMoneyBill, faChartBar, faMoneyCheck, faArrowCircleLeft, faCogs, faUserPlus, faUpload, faEnvelopeOpen, faListAlt, faUser, faUserShield, faPowerOff } from '@fortawesome/free-solid-svg-icons'
library.add(faHome, faComments, faCog, faCheckCircle, faChartArea, faMoneyBill, faChartBar, faMoneyCheck, faArrowCircleLeft, faCogs, faUserPlus, faUpload, faEnvelopeOpen, faListAlt, faUser, faUserShield, faPowerOff)

export default {
	name: "SalesMenu",
	props: {
		notPromoPage: false,
	},
	data() {
		return {
			showFunc: false,
			showRep: false,
			showSet: false
		}
	},
	components: {
		Link,
		CategoriesMenu,
		SearchMenu,
		FontAwesomeIcon,
	},
	methods: {
		...mapActions(usersStore, [
		]),
		hideSettings() {
			this.showSet = false;
		},
		hideReports() {
			this.showRep = false;
		},
		hideFunctions() {
			this.showFunc = false;
		},
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
		]),
		...mapState(rootStore, [
			'smallScreenMode',
		]),
	}
}
</script>

<style lang="scss" scoped>
.main-menu-navigation li {
	margin-right: 0.5rem;
}
</style>
