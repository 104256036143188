<template>
  <footer class="mt-auto border-t border-neutral-30 bg-neutral-10 py-24 md:py-32">
    <div class="container">
      <div class="flex flex-wrap justify-between -mx-16">
        <div class="w-full md:w-5/12 lg:w-1/3 px-16">
          <suspense>
            <se-img :alt="siteName" :src="logoDark" class="h-48 mb-8" />
          </suspense>
          <div class="hidden md:!block md:!visible">
            <div class="pb-24">
              <p class="text-b-m text-neutral-60 font-normal first-letter:uppercase pb-8" v-html="address" />
              <a :href="`mailto:${supportEmail}`" target="_blank" rel="noopener noreferrer" class="!text-primary-100">
                {{ supportEmail }}
              </a>
            </div>

            <div class="border-t border-neutral-30" />
            <div class="flex items-center pt-24 gap-16">
              <a
                v-if="socials?.facebook"
                :href="socials?.facebook"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block no-underline text-black"
                aria-label="Facebook"
              >
                <se-icon name="facebook" :size="24" />
              </a>
              <a
                v-if="socials?.instagram"
                :href="socials?.instagram"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block no-underline text-black"
                aria-label="Instagram"
              >
                <se-icon name="instagram" :size="24" />
              </a>
              <a
                v-if="socials?.youtube"
                :href="socials?.youtube"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block no-underline text-black"
                aria-label="YouTube"
              >
                <se-icon name="youtube" :size="24" />
              </a>
              <a
                v-if="socials?.tiktok"
                :href="socials?.tiktok"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-block no-underline text-black"
                aria-label="TikTok"
              >
                <se-svg name="tiktok" :size="24" />
              </a>
            </div>

            <p class="text-b-s text-neutral-60 font-normal pt-24">
              © 2024 {{ siteName }}
            </p>
          </div>
        </div>
        <div class="flex-grow hidden md:!block md:!visible" />
        <div class="w-full md:w-7/12 lg:w-1/2 flex flex-wrap">
          <div class="w-1/2 md:w-1/3 px-16 pb-24">
            <h6 class="text-h-l pb-16 font-semibold text-left md:text-center">
              {{ siteName }}
            </h6>
            <div class="flex flex-col items-start md:items-center">
              <Link
                href="/about"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                About Us
              </Link>
              <!-- <Link
                href="/advisor/register"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Become an {{ advisor }}
              </Link> -->
            </div>
          </div>
          <div class="w-1/2 md:w-1/3 px-16 pb-24">
            <h6 class="text-h-l pb-16 font-semibold text-left md:text-center">
              Support
            </h6>
            <div class="flex flex-col items-start md:items-center">
              <Link
                href="/how-it-works"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                How {{ siteName }} Works
              </Link>
              <Link
                v-show="currentUser.id"
                href="/contact-us"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Contact Us
              </Link>
              <a
                v-show="!currentUser.id"
                :href="`mailto:${supportEmail}`"
                target="_blank"
                rel="noopener noreferrer"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div class="w-1/2 md:w-1/3 px-16 pb-24">
            <h6 class="text-h-l pb-16 font-semibold text-left md:text-center">
              Resources
            </h6>
            <div class="flex flex-col items-start md:items-center">
              <Link
                href="/privacy-policy"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Privacy Policy
              </Link>
              <Link
                href="/terms"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Terms &amp; Conditions
              </Link>
              <Link
                href="/disclaimer"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Disclaimer
              </Link>
              <Link
                href="/cookie-policy"
                class="text-left md:text-center no-underline text-black mb-8 hover:!text-primary-100 hover:font-semibold transition-all"
              >
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="block md:hidden">
        <div class="border-t border-neutral-30" />

        <div class="flex items-center justify-center py-24 gap-16">
          <a
            v-if="socials?.facebook"
            :href="socials?.facebook"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-block no-underline text-black"
            aria-label="Facebook"
          >
            <se-icon name="facebook" :size="24" />
          </a>
          <a
            v-if="socials?.instagram"
            :href="socials?.instagram"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-block no-underline text-black"
            aria-label="Instagram"
          >
            <se-icon name="instagram" :size="24" />
          </a>
          <a
            v-if="socials?.youtube"
            :href="socials?.youtube"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-block no-underline text-black"
            aria-label="YouTube"
          >
            <se-icon name="youtube" :size="24" />
          </a>
          <a
            v-if="socials?.tiktok"
            :href="socials?.tiktok"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-block no-underline text-black"
            aria-label="TikTok"
          >
            <se-svg name="tiktok" :size="24" />
          </a>
        </div>
        <p class="text-b-s text-neutral-60 font-normal">
          Advisors are not employees of {{ siteName }}. {{ siteName }} is for entertainment purposes only.
        </p>
        <p class="text-b-s text-neutral-60 font-normal pt-8">
          © 2024 {{ siteName }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { theme } from '../../../theme';
import SeImg from '../ui-kit/img/Img.vue';
import SeIcon from '../ui-kit/icon/Icon.vue';
import Link from '../../components/Link.vue';
import { usersStore } from '../../store/users';
import SeSvg from '../ui-kit/svg/Svg.vue';

const {
  logoDark, siteName, socials, supportEmail, address,
} = theme.general;

const { currentUser } = storeToRefs(usersStore());
</script>

<script lang="ts">
export default {
  name: 'SeFooter',
};
</script>
