<template>
	<ul data-cy="nav" class="main-menu-navigation nav navbar-nav"
		:class="{ navigation: smallScreenMode }">
    <SearchMenu class="order-0" v-if="notPromoPage && smallScreenMode" />
    <div class="divider" />
    <li class="nav-item" v-show="smallScreenMode">
      <Link class="nav-link font-weight-bold" href="/advisors/online"><span>Online {{ $theme.captions.Advisors
			}}</span></Link>
    </li>
    <CategoriesMenu v-if="notPromoPage && smallScreenMode" />
    <div class="divider" />

		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="/dashboard/profile/edit" class="nav-link" data-cy="edit-profile">
			<font-awesome-icon icon="user" />
			<span>Edit Profile</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="/dashboard/profile/settings" class="nav-link" data-cy="account-settings">
			<font-awesome-icon icon="user-shield" />
			<span>Account Settings</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="javascript:;" class="nav-link" data-cy="logout" @click="$auth.logout()">
			<font-awesome-icon icon="power-off" />
			<span>Logout</span>
			</Link>
		</li>
	</ul>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { rootStore } from '/src/store/root'
import vClickOutside from 'click-outside-vue3'

import Link from '/src/components/Link.vue'
import CategoriesMenu from '/src/layouts/CategoriesMenu.vue'
import SearchMenu from '/src/layouts/SearchMenu.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faMoneyBillAlt, faComments, faComment, faUsers, faTimes, faUser, faUserShield, faPowerOff } from '@fortawesome/free-solid-svg-icons'
library.add(faHome, faMoneyBillAlt, faComments, faComment, faUsers, faTimes, faUser, faUserShield, faPowerOff)

export default {
	name: "AdvisorMenu",
	props: {
		notPromoPage: false,
	},
	data() {
		return {
		}
	},
	components: {
		Link,
		CategoriesMenu,
		SearchMenu,
		FontAwesomeIcon,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	methods: {
		...mapActions(usersStore, [
			'getUnreadInbox',
		]),
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
		]),
		...mapState(rootStore, [
			'smallScreenMode',
		]),
		unreadInbox() {
			return this.getUnreadInbox()
		},
	}
}
</script>

<style lang="scss" scoped>
.main-menu-navigation li {
	margin-right: 0.5rem;
}
</style>
