<template>
	<Link class="active-chat-button" href="/dashboard/chat"
		data-tooltip="You have active chat. Click this button go to chat!" data-tooltip-length="large"
		data-tooltip-pos="left" v-show="currentUser && currentUser.active_chat && $route.path !== '/dashboard/chat'">
	<font-awesome-icon icon="comments" /> You have an active chat. Click this message go to the chat.
	</Link>
</template>

<script>
import { mapState } from 'pinia'
import { usersStore } from '/src/store/users'

import Link from '/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments } from '@fortawesome/free-solid-svg-icons'
library.add(faComments)

export default {
	name: "ActiveChatButton",
	components: {
		Link,
		FontAwesomeIcon,
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
		])
	}
}
</script>

<style lang="scss" scoped>
@import "/src/styles/variables";

.active-chat-button {
	position: fixed;
	top: 3.5em;
	left: 0;
	width: 100%;
	z-index: 10000;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	background-color: rgba(0, 150, 0, 0.75);
	color: #ffffff;
	text-align: center;
	text-decoration: none;
	font-weight: bold;
}

@media screen and (max-width: $mobile) {
	.active-chat-button {
		top: 4em;
	}
}

</style>
