<template>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/users" class="flex items-center" data-cy="chats">
      <se-icon name="user" :size="20" class="mr-16" />
      <span>Users</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/dashboard/inbox" class="flex items-center" data-cy="advisors">
      <se-icon name="mail" :size="20" class="mr-16" />
      <span>Inbox <span v-if="getUnreadInbox()">({{ getUnreadInbox() }})</span></span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/info-sharing" class="flex items-center" data-cy="unsubscribe">
      <se-icon name="arrow-right" :size="20" class="mr-16" />
      <span>Info Sharing</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/mass-messaging" class="flex items-center" data-cy="account-settings">
      <se-icon name="send" :size="20" class="mr-16" />
      <span>Mass messaging</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/refill" class="flex items-center" data-cy="account-settings">
      <se-icon name="ticket-slash" :size="20" class="mr-16" />
      <span>Refund</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/chats" class="flex items-center" data-cy="account-settings">
      <se-icon name="message-circle" :size="20" class="mr-16" />
      <span>Chats</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/inbox" class="flex items-center" data-cy="account-settings">
      <se-icon name="inbox" :size="20" class="mr-16" />
      <span>Inboxes</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/admin-dashboard/transactions" class="flex items-center" data-cy="account-settings">
      <se-icon name="arrow-left-right" :size="20" class="mr-16" />
      <span>Transactions</span>
    </Link>
  </div>
</template>

<script lang="ts" setup>
import Link from '../../../../components/Link.vue';
import SeIcon from '../../../ui-kit/icon/Icon.vue';
import { usersStore } from '../../../../store/users';

const user = usersStore();
const { getUnreadInbox } = user;
</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenuSupport',
};
</script>
