<template>
  <div ref="headerSearch" class="l-header__search">
    <div class="w-32 h-32 md:w-44 md:h-44 flex items-center justify-center cursor-pointer" @click="open">
      <se-icon name="search" :size="20" />
    </div>
    <div ref="searchInput" class="l-header__search__popover flex items-center" :class="{ 'is-open': isOpen }">
      <se-input
        v-model="searchQuery"
        class="flex-grow"
        type="search"
        :size="isMobile ? 'small' : 'medium'"
        placeholder="Search"
        :clearable="true"
      >
        <template #prefix>
          <se-icon name="search" :size="20" />
        </template>
      </se-input>
      <div class="flex items-center pl-8 md:pl-32 !h-40 md:!h-56 bg-white !-mt-12 !pt-12">
        <se-button type="primary" size="small" @click="search">
          Search
        </se-button>
        <se-button
          v-if="!isMobile"
          native-type="button"
          type="tertiary"
          size="small"
          class="ml-8"
          @click="isOpen = false"
        >
          Cancel
        </se-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, computed, onMounted, onUnmounted,
} from 'vue';
import SeInput from '../../ui-kit/input/Input.vue';
import SeIcon from '../../ui-kit/icon/Icon.vue';
import SeButton from '../../ui-kit/button/Button.vue';
import { getWindow } from '../../helpers/getWindow';
import { getGlobalProperties } from '../../helpers/getGlobalProperties';

const searchQuery = ref<string>('');
const searchInput = ref<string>('');
const headerSearch = ref<any>(null);

const { $router } = getGlobalProperties();

const { windowWidth } = getWindow();
const isMobile = computed(() => windowWidth.value < 768);

const isOpen = ref<boolean>(false);

const open = () => {
  isOpen.value = true;
  const input = searchInput.value.querySelector('input');
  if (input) {
    input.focus();
  }
};

const search = () => {
  $router.push(`/search?q=${searchQuery.value}`);
  isOpen.value = false;
};

const handleClickOutside = (event: any) => {
  if (headerSearch.value && !headerSearch.value.contains(event.target)) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<script lang="ts">
export default {
  name: 'SeHeaderSearch',
};
</script>
