<template>
    <div role="navigation" data-menu="menu-wrapper" class="pt-1 pb-2" :class="[
        isAdmin ? 'zindex' : '',
        smallScreenMode
            ? 'main-menu menu-light menu-fixed menu-shadow'
            : 'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-fixed navbar-light navbar-without-dd-arrow navbar-shadow menu-border',
    ]">
        <div data-menu="menu-container" class="navbar-container main-menu-content container-fluid center-layout">
            <AdminMenu v-if="isAuthenticated && (isAdmin || (!isSales && !isSupport && $route.path.startsWith('/admin-dashboard')))" :notPromoPage="!isPromoPage" />
            <SupportMenu v-else-if="isSupport" :notPromoPage="!isPromoPage" />
            <SalesMenu v-else-if="isSales" :notPromoPage="!isPromoPage" />
            <UserMenu v-else-if="isUser || (!isAdvisor && $route.path.startsWith('/dashboard'))"
                :notPromoPage="!isPromoPage" />
            <AdvisorMenu v-else-if="isAdvisor" :notPromoPage="!isPromoPage" />
            <GuestMenu v-else :notPromoPage="!isPromoPage" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { rootStore } from '/src/store/root'
import { usersStore } from '/src/store/users'

import GuestMenu from '/src/layouts/GuestMenu.vue'
import AdminMenu from '/src/layouts/AdminMenu.vue'
import SupportMenu from '/src/layouts/SupportMenu.vue'
import SalesMenu from '/src/layouts/SalesMenu.vue'
import AdvisorMenu from '/src/layouts/AdvisorMenu.vue'
import UserMenu from '/src/layouts/UserMenu.vue'

export default {
    name: "navigation",
    components: {
        AdminMenu,
        SupportMenu,
        SalesMenu,
        AdvisorMenu,
        UserMenu,
        GuestMenu,
    },
    data() {
        return {
            isPromoPage: [
                '/top-psychic-readers',
                '/best-psychic-reader',
                '/promo',
                '/landing',
            ].includes(this.$route.path),
        }
    },
    methods: {
    },
    computed: {
        ...mapState(usersStore, [
            'isAdmin',
            'isSupport',
            'isSales',
            'isUser',
            'isAdvisor',
            'isAuthenticated',
        ]),
        ...mapState(rootStore, [
            'smallScreenMode',
        ]),
    }
}
</script>

<style lang="scss" scoped>
@import "/src/styles/variables";

.zindex {
    z-index: 100000 !important;
}

.main-menu-navigation :deep(a.is-active) {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
}

</style>
