<template>
	<ul data-cy="nav" class="main-menu-navigation nav navbar-nav"
		:class="{ navigation: smallScreenMode }">
    <SearchMenu class="order-0" v-if="notPromoPage && smallScreenMode" />
    <div v-if="smallScreenMode" class="divider"></div>
    <li class="nav-item order-2" v-show="smallScreenMode">
      <Link class="nav-link font-weight-bold" href="/advisors/online"><span>Online {{ $theme.captions.Advisors
			}}</span></Link>
    </li>
    <CategoriesMenu class="order-1" v-if="notPromoPage && smallScreenMode" />
    <div class="divider order-3"></div>
		<li class="nav-item order-3">
			<Link href="/how-it-works" class="nav-link" data-cy="how-it-works">
			<font-awesome-icon icon="question" />
			<span>How It Works</span>
			</Link>
		</li>
		<li class="nav-item order-3">
			<Link href="/about" class="nav-link" data-cy="about-us">
			<font-awesome-icon icon="users" />
			<span>About Us</span>
			</Link>
		</li>
	</ul>
</template>

<script>
import { mapState } from 'pinia'
import { usersStore } from '/src/store/users'
import { rootStore } from '/src/store/root'
import vClickOutside from 'click-outside-vue3'

import Link from '/src/components/Link.vue'
import CategoriesMenu from '/src/layouts/CategoriesMenu.vue'
import SearchMenu from '/src/layouts/SearchMenu.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestion, faUsers } from '@fortawesome/free-solid-svg-icons'
library.add(faQuestion, faUsers)

export default {
	name: "GuestMenu",
	props: {
		notPromoPage: false,
	},
	data() {
		return {
		}
	},
	components: {
		Link,
		CategoriesMenu,
		SearchMenu,
		FontAwesomeIcon,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	methods: {
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
			'unreadInbox',
		]),
		...mapState(rootStore, [
			'smallScreenMode',
		]),
	}
}
</script>

<style lang="scss" scoped>
.main-menu-navigation li {
	margin-right: 0.5rem;
}
</style>
