<template>
    <div class="popup" :class="{ 'is-active': true }" v-if="showStartIdentityVerification">
        <div class="popup-container">
            <div class="popup-header text-right">
                <button @click="setShowStartIdentityVerification(false)" type="button" class="close btn btn-warning"
                    aria-label="Close">
                    Close
                </button>
            </div>
            <!-- <div class="popup-header bg-success white">
                <h5 class="popup-title">Verify Your Identity</h5>
                <button @click="setShowStartIdentityVerification(false)" type="button" class="close btn" aria-label="Close">
                    <span aria-hidden="true"><font-awesome-icon icon="times" /></span>
                </button>
            </div> -->
            <div class="popup-scroll text-center" v-if="serviceName == 'veriff'">
                <h2 class="mt-4">Please Verify Your Identity!</h2>
                <p class="font-weight-bold">Fully automated AI identity verification
                    <br>keeps your info secure, no humans involved.
                </p>

                <button class="btn btn-success font-medium-5 font-weight-bold" @click="startVerification">
                    <font-awesome-icon icon="check" /> Verify My Identity
                </button>

                <h2 class="mt-5 pt-5">Why Verify Your Identity?</h2>
                <p>Verify your identity to prevent fraud. It confirms your identity and ensures that you're not using a
                    stolen identity or fake account to make purchases, protecting both clients and advisors.</p>

                <p class="font-weight-bold">Fully automated AI identity verification
                    <br>keeps your info secure, no humans involved.
                </p>

                <button class="btn btn-success font-medium-5 font-weight-bold" @click="startVerification">
                    <font-awesome-icon icon="check" /> Verify My Identity
                </button>

                <div class="youtube-video mt-5">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/gbIrhol4ES0"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen />
                </div>
            </div>

            <div class="popup-scroll text-center" v-else-if="serviceName == 'stripe_identity'">
                <div v-if="!waitingVerificationResults">
                    <h2 class="mt-4">Please Verify Your Identity!</h2>
                    <p class="font-weight-bold">Fully automated AI identity verification
                        <br>keeps your info secure, no humans involved.
                    </p>
    
                    <p>Verify your identity to prevent fraud. It confirms your identity and ensures that you're not using a
                        stolen identity or fake account to make purchases, protecting both clients and advisors.</p>
    
                    <button class="btn btn-success font-medium-5 font-weight-bold" @click="startVerification">
                        <font-awesome-icon icon="check" /> Verify My Identity
                    </button>
                </div>
                <div v-else-if="waitingVerificationResults">
                    <h2>Verifying your idenity</h2>
                    <div v-if="verificationStatus === null" class="alert alert-secondary">
                        Please wait...
                        <div>
                            <LoadingIcon />
                        </div>
                    </div>
                    <div v-else-if="verificationStatus" class="alert alert-success">
                        Verification succeeded
                    </div>
                    <div v-else class="alert alert-danger">
                        Verification failed
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'

import LoadingIcon from '/src/components/LoadingIcon.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faCheck, faTimes)

export default {
    data() {
        return {
            serviceName: '',
            waitingVerificationResults: false,
            verificationStatus: null,
            verificationStatusCheckInterval: null,
        }
    },
    components: {
        FontAwesomeIcon,
        LoadingIcon,
    },
    mounted() {
        if (this.isAuthenticated) {
            this.readVerificationService()
        }
    },
    watch: {
        isAuthenticated(newValue) {
            if (newValue) {
                this.readVerificationService()
            }
        },
    },
    methods: {
        ...mapActions(usersStore, [
            'setShowStartIdentityVerification',
            'getCurrentUser',
        ]),
        startVerification() {
            switch (this.serviceName) {
                case 'veriff':
                    this.$api.post('/api/user/start-veriff-id-verification')
                        .then(res => {
                            // window.location.href = res.data.url
                            // this.setShowStartIdentityVerification(false)

                            let _this = this
                            window.veriffSDK.createVeriffFrame({
                                url: res.data.url,
                                onEvent: function (msg) {
                                    console.log('veriff msg', msg)
                                    switch (msg) {
                                        case 'FINISHED':
                                            _this.startWaitingVerificationResults()
                                            break;
                                        case 'CANCELED':
                                            _this.$toast({
                                                message: '<b>Error: </b>' + 'Verification canceled',
                                                type: 'error'
                                            })
                                            _this.setShowStartIdentityVerification(false)
                                            break;
                                    }
                                }
                            })
                        })
                        .catch(error => {
                            if (error.response?.error) {
                                this.$toast({
                                    message: '<b>Error: </b>' + error.response.error,
                                    type: 'error'
                                })
                                this.setShowStartIdentityVerification(false)
                            }
                        })
                    break;
                case 'stripe_identity':
                    this.$api.post('/api/user/start-stripe-id-verification')
                        .then(async (res) => {
                            const stripe = Stripe(res.data.publishable_key);
                            const { error } = await stripe.verifyIdentity(res.data.client_secret);
                            if (!error) {
                                // window.location.href = '/dashboard/profile/settings';
                                this.startWaitingVerificationResults()
                            } else {
                                this.$toast({
                                    message: '<b>Error: </b>' + (error.message ?? 'Verification canceled'),
                                    type: 'error'
                                })
                                this.setShowStartIdentityVerification(false)
                            }
                        })
                        .catch(error => {
                            if (error.response?.error) {
                                this.$toast({
                                    message: '<b>Error: </b>' + error.response.error,
                                    type: 'error'
                                })
                                this.setShowStartIdentityVerification(false)
                            }
                        })
                    break;
                default:
                    this.$toast({
                        message: '<b>Error: </b>' + ('Unknown service ' + (this.serviceName ?? '')),
                        type: 'error'
                    })
                    this.setShowStartIdentityVerification(false)
            }
        },
        startWaitingVerificationResults() {
            this.waitingVerificationResults = true
            this.verificationStatusCheckInterval = setInterval(function() {
                this.getCurrentUser()
                    .then(res => {
                        if (this.currentUser.last_identity.status == 'approved') {
                            // Verification succeeded.
                            this.verificationStatus = true
                            this.closeDialog()
                        } else if (this.currentUser.last_identity.status == 'declined') {
                            // Verification failed.
                            this.verificationStatus = false
                            this.closeDialog()
                        } else {
                            // Waiting for results.
                        }
                    })
            }.bind(this), 1000)
        },
        closeDialog() {
            clearInterval(this.verificationStatusCheckInterval)
            this.verificationStatusCheckInterval = null
            setTimeout(function () {
                this.setShowStartIdentityVerification(false)
                this.waitingVerificationResults = false
                this.verificationStatus = null
            }.bind(this), 2000)
        },
        readVerificationService() {
            this.$api.get('/api/user/id-verification-service')
                .then(res => {
                    this.serviceName = res.data.service_name
                    switch (this.serviceName) {
                        case 'veriff':
                            let veriffTag = window.document.createElement("script")
                            veriffTag.setAttribute("src", "https://cdn.veriff.me/incontext/js/v1/veriff.js")
                            window.document.head.appendChild(veriffTag)
                            break
                        case 'stripe_identity':
                            let stripeTag = window.document.createElement("script")
                            stripeTag.setAttribute("src", "https://js.stripe.com/v3/")
                            window.document.head.appendChild(stripeTag)
                            break
                        default:
                            alert (`${this.serviceName} not supported.`)
                    }
                })
                .catch(error => {
                    if (error.response?.error) {
                        this.$toast({
                            message: '<b>Error: </b>' + error.response.error,
                            type: 'error'
                        })
                        this.setShowStartIdentityVerification(false)
                    }
                })
        }
    },
    computed: {
        ...mapState(usersStore, [
            'showStartIdentityVerification',
            'isUser',
            'isAdvisor',
            'isAuthenticated',
            'currentUser',
        ]),
    }
}
</script>

<style lang="scss" scoped>
@import "/src/styles/variables";

h2 {
    color: var(--primary);
    font-weight: bold;
}

.popup {
    z-index: 1000000 !important;
}

.popup-scroll {
    overflow-y: auto;
    max-height: 90%;
    max-height: 90vh;
    overflow-x: hidden;
    margin-right: -1rem;
    padding-right: 1rem;
}

.popup-container .popup-header {
    padding: 0.5rem;
    position: relative;
    margin: -0.75rem -0.75rem 0.25rem -0.75rem;
}

.popup-container .popup-header h5 {
    padding-top: 0.5rem;
    font-weight: bold;
}

.popup-container .popup-header .close.btn {
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
}

.youtube-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

button.btn-success {
    background-color: $green;
    color: #ffffff;
}

@media screen and (max-width: $mobile) {
    .popup-container {
        max-width: 98% !important;
        width: 98% !important;
    }
}

@media screen and (max-width: $tablet) {
    .verify-identity-icon {
        width: 7rem !important;
    }
}

@media screen and (min-width: $tablet) {
    .popup-container {
        max-width: 40rem !important;
    }

    .verify-identity-icon {
        width: 14rem !important;
    }
}

@media screen and (min-width: $desktop) {
    .popup-container {
        max-width: 40rem !important;
    }
}</style>