<template>
	<ul data-cy="nav" class="main-menu-navigation nav navbar-nav"
		:class="{ navigation: smallScreenMode }">
    <SearchMenu v-if="notPromoPage && smallScreenMode" />
    <div class="divider" />
    <li class="nav-item" v-show="smallScreenMode">
      <Link class="nav-link font-weight-bold" href="/advisors/online"><span>Online {{ $theme.captions.Advisors
			}}</span></Link>
    </li>
    <CategoriesMenu v-if="notPromoPage && smallScreenMode" />
    <div class="divider" />
		<li class="nav-item">
			<Link href="/admin-dashboard/users" class="nav-link" data-cy="dashboard">
			<font-awesome-icon icon="home" />
			<span>Users</span>
			</Link>
		</li>
		<li class="nav-item">
			<Link href="/dashboard/inbox" class="nav-link" data-cy="inbox">
			<font-awesome-icon icon="comments" />
			<span>Inbox <span v-if="unreadInbox">({{ unreadInbox }})</span></span>
			</Link>
		</li>
		<li data-menu="dropdown" class="dropdown nav-item" :class="{ show: showSet }" data-cy="settings-group">
			<Link href="javascript:" data-toggle="dropdown" class="dropdown-toggle nav-link" aria-expanded="true"
				@click="showSet = !showSet" v-click-outside="hideSettings"
				:class="[['/admin-dashboard/settings', '/admin-dashboard/promotions', '/admin-dashboard/categories'].includes($route.path) ? $router.linkActiveClass : '']">
			<font-awesome-icon icon="cog" /><span>Settings</span>
			</Link>
			<ul class="dropdown-menu">
				<li>
					<Link href="/admin-dashboard/settings" class="nav-link" data-cy="settings">
					<font-awesome-icon icon="cog" />
					<span>Settings</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/promotions" class="nav-link" data-cy="promotions">
					<font-awesome-icon icon="check-circle" />
					<span>Promotions</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/categories" class="nav-link" data-cy="categories">
					<font-awesome-icon icon="list" />
					<span>Categories</span>
					</Link>
				</li>
			</ul>
		</li>
		<li data-menu="dropdown" class="dropdown nav-item" :class="{ show: showRep }">
			<Link href="javascript:" data-toggle="dropdown" class="dropdown-toggle nav-link" aria-expanded="true"
				@click="showRep = !showRep" v-click-outside="hideReports" data-cy="reports-dropdown"
				:class="[['/admin-dashboard/withdraws', '/admin-dashboard/reports', '/admin-dashboard/sales', '/admin-dashboard/info-sharing'].includes($route.path) ? $router.linkActiveClass : '']">
			<font-awesome-icon icon="chart-area" /><span>Reports</span>
			</Link>

			<ul class="dropdown-menu">
				<li>
					<Link href="/admin-dashboard/withdraws" class="nav-link" data-cy="withdraws">
					<font-awesome-icon icon="money-bill" />
					<span>Withdraws</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/reports" class="nav-link" data-cy="reports">
					<font-awesome-icon icon="chart-bar" />
					<span>Reports</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/sales" class="nav-link" data-cy="sales">
					<font-awesome-icon icon="money-check" />
					<span>Sales</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/info-sharing" class="nav-link" data-cy="info">
					<font-awesome-icon icon="arrow-circle-left" />
					<span>Info Sharing</span>
					</Link>
				</li>
			</ul>
		</li>

		<li data-menu="dropdown" class="dropdown nav-item" :class="{ show: showFunc }">
			<Link href="javascript:" data-toggle="dropdown" class="dropdown-toggle nav-link" aria-expanded="true"
				@click="showFunc = !showFunc" v-click-outside="hideFunctions"
				:class="[['/admin-dashboard/mass-messaging', '/admin-dashboard/refill', '/admin-dashboard/new-admin', '/admin-dashboard/functions', '/admin-dashboard/data-import'].includes($route.path) ? $router.linkActiveClass : '']">
			<font-awesome-icon icon="cogs" /><span>Functionalities</span>
			</Link>
			<ul class="dropdown-menu" style="width: 13rem;">
				<li>
					<Link href="/admin-dashboard/new-admin" class="nav-link" data-cy="new-admin">
					<font-awesome-icon icon="user-plus" />
					<span>New admin</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/functions" class="nav-link" data-cy="functions">
					<font-awesome-icon icon="cogs" />
					<span>Functions</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/refill" class="nav-link" data-cy="refill">
					<font-awesome-icon icon="arrow-circle-left" />
					<span>Refund</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/mass-messaging" class="nav-link" data-cy="mass-messaging">
					<font-awesome-icon icon="comments" />
					<span>Mass messaging</span>
					</Link>
				</li>
				<li>
					<Link href="/admin-dashboard/data-import" class="nav-link" data-cy="import">
					<font-awesome-icon icon="upload" />
					<span>Import data</span>
					</Link>
				</li>
			</ul>
		</li>

		<li class="nav-item">
			<Link href="/admin-dashboard/chats" class="nav-link" data-cy="chats">
			<font-awesome-icon icon="comments" />
			<span>Chats</span>
			</Link>
		</li>
		<li class="nav-item">
			<Link href="/admin-dashboard/inbox" class="nav-link" data-cy="inboxes">
			<font-awesome-icon icon="envelope-open" />
			<span>Inboxes</span>
			</Link>
		</li>
		<li class="nav-item">
			<Link href="/admin-dashboard/transactions" class="nav-link" data-cy="transactions">
			<font-awesome-icon icon="list-alt" />
			<span>Transactions</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="/dashboard/profile/edit" class="nav-link" data-cy="edit-profile">
			<font-awesome-icon icon="user" />
			<span>Edit Profile</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="/dashboard/profile/settings" class="nav-link" data-cy="account-settings">
			<font-awesome-icon icon="user-shield" />
			<span>Account Settings</span>
			</Link>
		</li>
		<li class="nav-item" @click="hideSideBarMenu" v-show="smallScreenMode">
			<Link href="javascript:;" class="nav-link" data-cy="logout" @click="$auth.logout()">
			<font-awesome-icon icon="power-off" />
			<span>Logout</span>
			</Link>
		</li>
	</ul>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { rootStore } from '/src/store/root'
import vClickOutside from 'click-outside-vue3'

import Link from '/src/components/Link.vue'
import CategoriesMenu from '/src/layouts/CategoriesMenu.vue'
import SearchMenu from '/src/layouts/SearchMenu.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faComments, faCog, faCheckCircle, faList, faChartArea, faMoneyBill, faChartBar, faMoneyCheck, faArrowCircleLeft, faCogs, faUserPlus, faUpload, faEnvelopeOpen, faListAlt, faUser, faUserShield, faPowerOff } from '@fortawesome/free-solid-svg-icons'
library.add(faHome, faComments, faCog, faCheckCircle, faList, faChartArea, faMoneyBill, faChartBar, faMoneyCheck, faArrowCircleLeft, faCogs, faUserPlus, faUpload, faEnvelopeOpen, faListAlt, faUser, faUserShield, faPowerOff)

export default {
	name: "AdminMenu",
	props: {
		notPromoPage: false,
	},
	data() {
		return {
			showFunc: false,
			showRep: false,
			showSet: false
		}
	},
	components: {
		Link,
		CategoriesMenu,
		SearchMenu,
		FontAwesomeIcon,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	methods: {
		...mapActions(usersStore, [
			'getUnreadInbox',
		]),
		hideSettings() {
			this.showSet = false;
		},
		hideReports() {
			this.showRep = false;
		},
		hideFunctions() {
			this.showFunc = false;
		},
	},
	computed: {
		...mapState(usersStore, [
			'currentUser',
			'isAdmin',
		]),
		...mapState(rootStore, [
			'smallScreenMode',
		]),
		unreadInbox() {
			return this.getUnreadInbox()
		},
	}
}
</script>

<style lang="scss" scoped>
.main-menu-navigation li {
	margin-right: 0.5rem;
}
</style>
