<template>
    <div>
        <nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-static-top navbar-light navbar-border"
            :class="{
                'fixed-top': isChatPage && smallScreenMode,
                zindex: zIndexNavbar,
            }">
            <div class="navbar-wrapper container-fluid px-0">

                <div class="navbar-header" style="height: 68px;">
                    <ul class="nav navbar-nav h-100">

                        <li class="nav-item mobile-menu d-md-none mr-auto">
                            <a href="javascript:;" class="nav-link nav-menu-main menu-toggle hidden-xs"
                                @click="toggleSideBarMenu">
                                <font-awesome-icon icon="bars" />
                            </a>
                        </li>

                        <li class="nav-item">
                            <Link :href="isAuthenticated ? '/home' : '/'" class="navbar-brand">
                            <img :src="'/static/images/theme_icons/' + $theme.name + '/logo_horizontal.png'"
                                style="height: 3.5rem; width: auto;">
                            </Link>
                        </li>
                    </ul>
                </div>

                <div class="navbar-container container-fluid border-top border-light" style="padding: 0 10px;">

                    <div id="navbar-mobile" class="navbar-collapse d-flex justify-content-end pt-1 show">
                        <!-- :class="{show: !isChatPage && smallScreenMode}" -->

                        <ul class="nav navbar-nav mr-auto float-left" v-show="!isPromoPage && !smallScreenMode">
                            <li>
                                <Link class="nav-link font-weight-bold mr-3" href="/advisors/online">Online {{
                                    $theme.captions.Advisors }}</Link>
                            </li>
                            <CategoriesMenu />
                            <SearchMenu />
                        </ul>

                        <ClientAreaMenu v-if="isAuthenticated" />

                        <ul v-if="!isAuthenticated && !isPromoPage" v-show="!smallScreenMode"
                            class="signin nav navbar-nav d-flex justify-content-center px-0 mx-0">
                            <li class="nav-item">
                                <Link class="nav-link is-button" href="/login">
                                <span class="btn btn-outline-primary">Sign In</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link is-button" href="/register">
                                <span class="btn btn-primary">Sign Up</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

        <Navigation v-if="!hideNavigationMenu" />
    </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { usersStore } from '/src/store/users'
import { rootStore } from '/src/store/root'

import Navigation from '/src/layouts/Navigation.vue'
import Link from '/src/components/Link.vue'
import ClientAreaMenu from '/src/layouts/ClientAreaMenu.vue'
import CategoriesMenu from '/src/layouts/CategoriesMenu.vue'
import SearchMenu from '/src/layouts/SearchMenu.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faAngleDown, faUsers } from '@fortawesome/free-solid-svg-icons'
library.add(faBars, faAngleDown, faUsers)

export default {
    name: "dashboard-header",
    data() {
        return {
            showMobileNavbar: true,
            fixNavbar: false,
            isPromoPage: [
                '/top-psychic-readers',
                '/best-psychic-reader',
                '/landing',
                '/promo',

            ].includes(this.$route.path),
            isChatPage: [
                '/dashboard/chat',
                '/dashboard/inbox',
            ].includes(this.$route.path),
            zIndexNavbar: this.isAdmin,
            hideNavigationMenu: [
                '/top-psychic-readers',
                '/best-psychic-reader',
                '/landing',
                '/promo',
            ].includes(this.$route.path),
        }
    },
    components: {
        Navigation,
        Link,
        ClientAreaMenu,
        CategoriesMenu,
        SearchMenu,
        FontAwesomeIcon,
    },
    methods: {
        ...mapActions(rootStore, [
            'toggleSideBarMenu',
            'hideSideBarMenu',
        ]),
    },
    computed: {
        ...mapState(usersStore, [
            'currentUser',
            'isAuthenticated',
            'isAdmin',
        ]),
        ...mapState(rootStore, [
            'smallScreenMode',
        ]),
    }
}
</script>

<style lang="scss">
@import "/src/styles/variables";

/* Mobile top menus */
@media screen and (max-width: $tablet) {
    .navbar-nav .nav-item.mobile-menu {
        display: inline-block;
        padding-top: 0.1em;
        right: 0.25em;
        position: fixed;
    }

    .main-menu {
        min-width: 20em;
    }

    .navbar-nav .nav-item.mobile-menu svg {
        font-size: 2.5rem !important;
    }

    .header-navbar .navbar-header .navbar-brand img {
        margin-top: 0rem;
    }

    .header-navbar .navbar-header .navbar-brand {
        top: 0 !important;
        left: 0.6em !important;
        position: fixed !important;
        transform: none !important;
    }

    .main-menu.menu-fixed {
        z-index: 1000000 !important;
        right: -20em !important;
        left: auto;
        transition: right 0.35s;
        transform: none;
        padding-right: 1em;
        max-height: calc(100% - 5rem);
        overflow-y: auto;
    }

    .menu-open .main-menu.menu-fixed {
        right: 0em !important;
    }

    .navbar-header {
        position: fixed !important;
        top: 0;
        width: 100%;
        z-index: 999999 !important;
        background: var(--background);
        border-bottom: 1px solid rgb(228, 231, 237);
    }

    .main-menu.menu-light .navigation>li.show>ul {
        display: block !important;
    }

    .header-navbar {
        min-height: 0 !important;
    }

    .header-navbar .navbar-container {}

    #navbar-mobile .nav.navbar-nav:nth-of-type(2) {
        z-index: 1000000 !important;
        position: fixed;
        right: 2.25em;
        top: 0.7em;
    }

    #navbar-mobile .nav.navbar-nav.signin {
        right: 2.75em;
        top: 0.4em;
    }

    .navbar-nav .nav-search button {
        padding-left: 1.3em;
    }

    .navbar-nav .nav-search .search-input {
        margin-top: 1em;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        right: 0;
    }

    /* Hiding elements that appear between styles loaded and scripts loaded */
    body:not(.fixed-navbar) .navbar-expand-md+div,
    body:not(.fixed-navbar) #navbar-mobile .nav.navbar-nav:nth-of-type(2),
    body:not(.fixed-navbar) #navbar-mobile .navbar-nav:nth-of-type(1) {
        display: none;
    }
}

/* Other elements for mobile screens */
.fixed-navbar .modal {
    margin-top: 5em;
}

.header-navbar .navbar-container .show .dropdown-menu {
    overflow-y: auto;
}

@media screen and (min-width: $widescreen) {
    .navbar-expand-md .navbar-nav .dropdown-menu {
        right: 50px;
    }
}
</style>

<style lang="scss" scoped>
/* @import "perfect-scrollbar/css/perfect-scrollbar.css"; */

.mega-dropdown {
    .nav-link {
        font-size: 1.2rem;
    }
}

.nav-link {
    &.is-button {
        padding: 8px 0.6rem !important;
    }
}

.zindex {
    z-index: 100002 !important;
}

.end-me {
    justify-content: flex-end;
}

.navbar-brand img {
    margin-top: 1rem;
}

.btn {
    padding: 0.75rem 1rem;
}

.header-navbar .navbar-header {
    width: auto;
    left: 0;
}

.btn.btn-primary:hover {
    background: var(--primaryHover);
}

.btn.btn-outline-primary:hover {
    background: var(--secondaryHover);
    border-color: var(--secondaryHover);
}

.navbar-wrapper, .header-navbar .navbar-container{
  max-width: 1336px !important;
}
</style>
